import { FieldData, WorkflowItem } from "@/constants";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from './index.module.scss';
import { ParamNode } from "@/models/sd/comfy";
import { Button, Form, Input, Space, Spin, message, Image, FormInstance, Result, Modal, Badge, Tabs, Divider } from "antd";
import { Profile } from "@/models/common/user";
import { SdTaskResult, SdTask } from "@/models/sd/SdFile";
import { getSdTask, listTaskResultsByTaskIds } from "@/services/SdFile";
import { getProfileData } from "@/services/user";
import { getWorkflowInviteCode, onDownload, saveWorkflowInviteCode, sleep } from "@/utils";
import { DownloadOutlined, SwapOutlined, RotateLeftOutlined, RotateRightOutlined, ZoomOutOutlined, ZoomInOutlined, UpCircleOutlined, DownCircleOutlined } from "@ant-design/icons";
import { Loading } from "@/components/Loading";
import { runWorkflow } from "@/services/Workflow";
import { listWorks, listWorksPaged } from "@/services/Works";
import { useGlobalStore } from "@/store";
import { useTranslation } from "react-i18next";
import ApiDocumentation from "./OpenAPI";
import SignAgreementDialog from "@/components/SignAgreementDialog";
import PaymentModal from "@/components/PaymentDialog";
import ParamRender from "./components/ParamRender";
import ParamContainer from "./components/ParamRender";
import { NormalOutputRender } from "./components/OutputRender";


const WorkflowApp = (props) => {

  const { code } = useParams();
  const [ workflow, setWorkflow ] = useState<WorkflowItem>({});
  const [form] = Form.useForm();
  const [sdTaskResults, setSdTaskResults] = useState<SdTaskResult[]>([]);
  const [generating, setGenerating] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [profileData, setProfileData] = useState<Profile>({});
  const currentUser = useGlobalStore((state) => state.currentUser);
  const showLoginModal = useGlobalStore((state) => state.showLoginModal);
  const showSignAgreementModal = useGlobalStore((state) => state.showSignAgreementModal);
  const [ noPermission, setNoPermission ] = useState(false);
  const [ needInviteCode, setNeedInviteCode ] = useState(false);
  const [ inviteCode, setInviteCode ] = useState('');
  const [currentWorks, setCurrentWorks] = useState<SdTaskResult>();
  const [t] = useTranslation();
  const navigate = useNavigate();
  
  const [isMobilePortrait, setIsMobilePortrait] = useState(false);

  const isSignAgreementModalOpen = useGlobalStore((state) => state.isSignAgreementModalOpen);
  const hideSignAgreementModal = useGlobalStore((state) => state.hideSignAgreementModal);
  const showPaymentModal = useGlobalStore((state) => state.showPaymentModal);
  const isPaymentModalOpen = useGlobalStore((state) => state.isPaymentModalOpen);
  const hidePaymentModal = useGlobalStore((state) => state.hidePaymentModal);

  useEffect(() => {
    function handleResize() {
      // 获取屏幕宽度和高度
      const screenWidth = window.innerWidth || document.documentElement.clientWidth;
      const screenHeight = window.innerHeight || document.documentElement.clientHeight;

      // 判断是否是移动端并且处于竖屏状态
      setIsMobilePortrait(screenWidth < screenHeight && /Mobi|Android/i.test(navigator.userAgent));
    }
    // 添加窗口大小改变事件监听器
    window.addEventListener('resize', handleResize);

    // 初始状态下也触发一次handleResize以更新状态
    handleResize();

    // 清理函数，在组件卸载时移除事件监听器
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    async function init() {
      const profileResp = await getProfileData();
      if (profileResp?.data) {
        setProfileData(profileResp.data);
      }
      const inviteCode = await getWorkflowInviteCode(code);
      if (inviteCode) {
        setInviteCode(inviteCode);
      }
    }
    init();
  }, []);

  const handleGenerate = async (ignoreSignAgreement, param) => {
    if (!currentUser?.publicId) {
      showLoginModal(false);
      return;
    }
    if (!ignoreSignAgreement && !currentUser?.signAgreementAt) {
      showSignAgreementModal()
      return;
    }
    // 判断 M 币是否足够，不足则弹出支付框
    const balance = +(profileData?.mcoinAccount?.balance || 0);
    const workflowCost = +(workflow?.costByRecord || workflow?.costByCount);
    if (balance < workflowCost) {
      showPaymentModal();
      return;
    }

    try {
      const res = await runWorkflow(param, inviteCode);

      if (!res?.success || !res.data?.publicId) {
        messageApi.error(`${t('generateFailed')}: ${res?.message}`);
        return;
      }
      setGenerating(true);
      let initLoadingCount = 0;
      initLoadingCount += (param?.batch_size || 1);
      if (initLoadingCount > 0) {
        for (let i = 0; i < initLoadingCount; i++) {
          sdTaskResults.unshift({
            loading: true,
          })
        }
        setSdTaskResults(sdTaskResults);
      }

      const profileResp = await getProfileData();
      if (profileResp?.data) {
        setProfileData(profileResp.data);
      }
    } catch (e) {
      messageApi.error(`${t('generateFailed')}: ${e?.message}`);
    }
    setGenerating(false);
  }

  useEffect(() => {
    const fetchWorkflow = async () => {
      const inviteCode = await getWorkflowInviteCode(code);
      const response = await fetch(`/open/api/v1/workflows/${code}?inviteCode=${inviteCode}`);
      const data = await response.json();
      if (!data.success) {
        message.error(`获取工作流失败: ${data.message}`);
        if (data.code === 403) {
          setNoPermission(true);
        } else if (data.code === 1004031) {
          setNeedInviteCode(true);
        } else if (data.code === 404) {
          navigate('/404');
        }
        return;
      }
      const workflow = data.data;
      const paramTpl = JSON.parse(workflow?.paramTpl || '{}');
      setWorkflow({
        ...workflow,
        contentTpl: JSON.parse(workflow?.contentTpl || '{}'),
        paramTpl,
        outputTpl: JSON.parse(workflow?.outputTpl || '{}'),
      });
    };
    fetchWorkflow();
  }, [code, currentUser]);

  const handleSaveInviteCode = async (values) => {
    saveWorkflowInviteCode(code, values.inviteCode);
    location.reload();
  }

  if (noPermission || needInviteCode) {
    return <>
      <Modal title="请输入邀请码" open={needInviteCode} closable={false} closeIcon={null} footer={null}>
        <Form layout="inline" onFieldsChange={(changedFields) => {
          setInviteCode(changedFields[0].value);
        }} fields={[{
          name: 'inviteCode',
          value: inviteCode
        }]} onFinish={handleSaveInviteCode}>
          <div style={{ display: 'flex', width: '100%' }}>
            <Form.Item style={{ flex: 'auto' }} rules={[{ required: true }]} label="" name="inviteCode">
              <Input />
            </Form.Item>
            <div className={styles.submit}>
              <Button htmlType="submit" disabled={!inviteCode} className={styles.submitButton} type="primary">确定</Button>
            </div>
          </div>
        </Form>
      </Modal>
      <Result
        status="403"
        title={<div className={styles.errorTitle}>403 无权限访问</div>}
        subTitle={<div className={styles.errorSubTitle}>{`Sorry, you are not authorized to access workflow ${code}.`}</div>}
        extra={<Button type="primary" onClick={() => {
          navigate('/');
        }}>Back Home</Button>}
      />
    </>;
  }
  
  return (
    <><Spin spinning={globalLoading}><div className={styles.container}>
      {contextHolder}
      <div className={styles.content}>
        <Tabs type="card" tabPosition={isMobilePortrait ? "top" : "left"} defaultActiveKey="1">
          <Tabs.TabPane tab={t('workflow.app.playground')} key="1">
            <div className={`flex w-full ${isMobilePortrait ? 'flex-col px-4 gap-4' : ''}`}>
              <div className={`flex-1 ${isMobilePortrait ? 'w-full' : 'min-w-96 w-96 max-w-96'}`}>
                <ParamContainer 
                  code={code}
                  workflow={workflow} 
                  profileData={profileData}
                  isMobilePortrait={isMobilePortrait}
                  onGenerate={(param) => {
                    handleGenerate(false, param);
                  }} 
                  currentWorks={currentWorks}
                />
              </div>
              <div style={{ width: 0.5 }} className="mx-2 border-r border-dashed border-gray-300" />
              <div className="flex-auto">
                {
                  <NormalOutputRender
                    code={code}
                    generating={generating}
                    isMobilePortrait={isMobilePortrait}
                    onSelect={(works) => {
                      setCurrentWorks(works);
                    }}
                  />
                }
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("workflow.app.api")}>
            <div className={styles.apiArea}>
              <ApiDocumentation workflow={workflow} APITokenDescription={t('OpenAPI.APITokenDescription')} />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
    </Spin>

    <SignAgreementDialog
      onCancel={hideSignAgreementModal}
      open={isSignAgreementModalOpen}
      onOk={handleGenerate?.bind(null, true)}
    />
    <PaymentModal
      onCancel={hidePaymentModal}
      open={isPaymentModalOpen}
    />
    <Modal open={needInviteCode}>
      <Form fields={[{
        name: 'inviteCode',
        value: inviteCode
      }]} onFinish={handleSaveInviteCode}>
        <Form.Item rules={[{ required: true }]} label="请输入邀请码" name="inviteCode">
          <Input />
        </Form.Item>
        <div className={styles.submit}>
          <Button disabled={!inviteCode} className={styles.submitButton} type="primary">确定</Button>
        </div>
      </Form>
    </Modal>
    </>
  );
};

export default WorkflowApp;
