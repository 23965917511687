
import { Button, Empty, Modal, Table, Tabs, message } from 'antd';
import styles from './index.module.scss';
import { useGlobalStore } from '@/store';
import { useEffect, useState } from 'react';
import { createPaymentOrder, getPaymentOrderStatus, listMcoinRecords, listPaymentProducts } from '@/services/user';

import moment from 'moment';

const MCoinRecordTypeMap = {
  add: '收入',
  cost: '支出',
  refund: '退款',
};

const Member: React.FC = () => {
  const user = useGlobalStore((state) => state.currentUser);
  const getUserInfo = useGlobalStore((state) => state.getUserInfo);

  const [ mcoinRecord, setMcoinRecord ] = useState([]);
  const [ paymentProducts, setPaymentProducts ] = useState([]);
  const [ selectedProduct, setSelectedProduct ] = useState(null);
  const [ showPaymentModal, setShowPaymentModal ] = useState(false);
  const [ qrcode, setQrcode ] = useState('');

  async function loopPaymentOrderStatus(orderId: string) {
    const res = await getPaymentOrderStatus(orderId);
    if (res.data === 'SUCCESS') {
      setShowPaymentModal(false);
      await getUserInfo();
      await fetchMcoinRecord();
      message.success('支付成功');
    } else {
      setTimeout(() => {
        loopPaymentOrderStatus(orderId);
      }, 3000);
    }
  }

  async function fetchMcoinRecord() {
    const res = await listMcoinRecords();
    setMcoinRecord(res.data);
  }

  async function fetchPaymentProducts() {
    const res = await listPaymentProducts();
    setPaymentProducts(res.data);
  }

  const handleSelectProduct = (product: any) => {
    setSelectedProduct(product);
  };

  const handleCreateOrder = async () => {
    if (!selectedProduct) return;
    const res = await createPaymentOrder(selectedProduct.code);
    if (!res || !res.data) {
      message.error('创建订单失败');
      return;
    }
    const data = res.data;
    const { qrcodeUrl, publicId } = data;
    if (qrcodeUrl) {
      setQrcode(qrcodeUrl);
      setShowPaymentModal(true);
      loopPaymentOrderStatus(publicId);
    }
  };

  const handleDismissModal = async () => {
    setShowPaymentModal(false);
    await getUserInfo();
    await fetchMcoinRecord();
  };

  useEffect(() => {
    fetchMcoinRecord();
    fetchPaymentProducts();
  }, []);

  return <div className={styles.member}>
    <div className={styles.top}>
      <div className={styles.avatar}>
        <img src={user?.avatar} />
      </div>
      <div className={styles.right}>
        <div className={styles.title}>{user?.displayName || user?.name}</div>
        <div className={styles.subTitle}>M 币：{user?.mcoinAccount?.balance}</div>
      </div>
    </div>
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane tab="M 币充值" key="1">
        <div className={styles.products}>
          {
            paymentProducts.map((product: any) => (
              <div 
                key={product.publicId} 
                className={selectedProduct?.publicId === product.publicId ? styles.productSelected : styles.product} 
                onClick={handleSelectProduct.bind(null, product)}
              >
                <div className={styles.title}>{product.title}</div>
                {/* <div className={styles.description}>{product.description}</div> */}
                <div className={styles.priceGroup}>
                  <div className={styles.displayPrice}>{(+product.displayPrice)/100} 元</div>
                  <div className={styles.price}>{(+product.price)/100} 元</div>
                  <div className={styles.mcoin}>{product.mcoinAmount} M 币</div>
                </div>
              </div>
            ))
          }
        </div>
        <div className={styles.action}><Button type="primary" disabled={!selectedProduct} onClick={handleCreateOrder}>立即充值</Button></div>
      </Tabs.TabPane>
      <Tabs.TabPane tab="M 币记录" key="2">
        {
          mcoinRecord?.length 
            ? <Table
                dataSource={mcoinRecord}
                columns={[
                  {
                    title: '时间',
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: (text: string) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
                  },
                  {
                    title: '变动',
                    dataIndex: 'type',
                    key: 'type',
                    render: (text: string) => MCoinRecordTypeMap[text] || '-',
                  },
                  {
                    title: '金额(M币)',
                    dataIndex: 'amount',
                    key: 'amount',
                  },
                  {
                    title: '描述',
                    dataIndex: 'description',
                    key: 'description',
                  },
                  {
                    title: '余额',
                    dataIndex: 'balance',
                    key: 'balance',
                  },
                ]}
              />
            : <Empty />
        }
      </Tabs.TabPane>
    </Tabs>
    <Modal 
      title="微信支付" 
      open={showPaymentModal}
      onCancel={handleDismissModal}
      footer={null}
    >
      <div className={styles.qrcode}>
        <div className={styles.qrcodeWrapper}>
          <img src={qrcode} />
        </div>
      </div>
      <div className={styles.tip}>--请使用微信扫码支付--</div>
    </Modal>
  </div>;
};

export default Member;
