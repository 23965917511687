import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Button, Dropdown, FloatButton, Layout, Modal, Popover, Select, Space } from 'antd';
import { CommentOutlined, CustomerServiceOutlined, GithubOutlined, MailOutlined, MenuOutlined, VerticalAlignTopOutlined } from '@ant-design/icons';
import { useGlobalStore } from '@/store'

import DashboardMenu from './menu';
import styles from './index.module.scss';
import GroupQrCode from '@/components/Feedback/GroupQRCode';
import { useTranslation } from 'react-i18next';

const { Header, Content } = Layout;

const TransparentHeader = ({
  unselected,
  setUnselected,
  onMenuSelect
}) => {
  return <div className={styles.transparentHeader}>
    <div>
      <div className={styles.logo} />
    </div>
    <div className={styles.right}>
      <div className={styles.hozMenu} ><DashboardMenu theme='dark' unselected={unselected} mode="horizontal" onSelect={onMenuSelect} /></div>
      <NavActions onEnterUserCenter={setUnselected?.bind(null, true)} />
      <Dropdown menu={{items: [
        {
          label: (
            <DashboardMenu onSelect={onMenuSelect} unselected={unselected} mode="vertical"/>
          ),
          key: '0',
        },
      ]}}>
        <div className={styles.verMenu}><Button type="text"><MenuOutlined style={{ fontSize: 20 }} /></Button></div>
      </Dropdown>
    </div>
  </div>;
}

const NavActions = (props) => {
  const { onEnterUserCenter } = props;
  const locale = useGlobalStore((state) => state.locale);
  const user = useGlobalStore((state) => state.currentUser);
  const logout = useGlobalStore((state) => state.logout);
  const showLoginModal = useGlobalStore((state) => state.showLoginModal);
  const negative = useNavigate();
  const [ t, i18n ] = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  const handleSignUp = () => {
    showLoginModal();
  }

  const handleLogout = async () => {
    await logout();
  }

  const entryUserCenter = () => {
    negative(`/space/${user?.publicId}`);
    onEnterUserCenter?.();
  }
  return <div className={styles.navActions}>
    <Select 
      className={styles.localeSelect}
      value={locale}
      onChange={(value) => {
        useGlobalStore.setState({ locale: value });
      }}
    >
      <Select.Option value="zh-CN">简体中文</Select.Option>
      <Select.Option value="en-US">English</Select.Option>
    </Select>
    {
      user?.publicId 
      ?  <Dropdown menu={{items: [
        {
          label: (
            <Button type="text" onClick={entryUserCenter}>
              {t('UserCenter')}
            </Button>
          ),
          key: '0',
        },
        {
          label: (
            <Button type="text" onClick={handleLogout}>
              {t('Logout')}
            </Button>
          ),
          key: '1',
        },
      ]}}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <Avatar src={user.avatar}>{ user.avatar ? null : user.email?.slice(0,1)}</Avatar> 
            </Space>
          </a>
        </Dropdown>
      : <div style={{display: 'flex'}}>
        <Button className={styles.signUp} onClick={handleSignUp} type="primary">{t('SignIn')}</Button>
      </div>
    }
  </div>;
}

const App: React.FC = () => {
  const locale = useGlobalStore((state) => state.locale);
  
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [unselected, setUnselected] = useState(false);
  const [ t, i18n ] = useTranslation();
  const negative = useNavigate();
  const location = useLocation();
  const contentRef = useRef();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);


  const onMenuSelect = () => {
    setUnselected(false);
  }

  const goHome = () => {
    negative('/');
  }

  return (
    <Layout className="layout">
      {
        (showBackToTop || location?.pathname !== '/') 
          ? <Header className={location?.pathname !== '/' ? styles.header : styles.fixedHeader}>
        <div>
          <div onClick={goHome} className={styles.logo} />
        </div>
        <div className={styles.right}>
          <div className={styles.hozMenu} ><DashboardMenu unselected={unselected} mode="horizontal" onSelect={onMenuSelect} /></div>
          <NavActions onEnterUserCenter={setUnselected?.bind(null, true)} />
          <Dropdown menu={{items: [
            {
              label: (
                <DashboardMenu onSelect={onMenuSelect} unselected={unselected} mode="vertical"/>
              ),
              key: '0',
            },
          ]}}>
            <div className={styles.verMenu}><Button type="text"><MenuOutlined style={{ fontSize: 20 }} /></Button></div>
          </Dropdown>
        </div>
      </Header> : <TransparentHeader unselected={unselected} setUnselected={setUnselected} onMenuSelect={onMenuSelect}  />
      }
      <Content 
        ref={contentRef} 
        style={{ height: location?.pathname !== '/' ? 'calc(100vh - 64px)' : '100vh', overflowY: 'scroll' }}
        onScroll={() => {
          setShowBackToTop(contentRef?.current?.scrollTop  > 64);
        }}
      >
        <Outlet />
      </Content>
      { showBackToTop ? <FloatButton 
          icon={<VerticalAlignTopOutlined />}
          style={{ right: 24, bottom: 228 }}
          onClick={() => {
            contentRef?.current?.scrollTo?.({
              top: 0,
              behavior: 'smooth',
            });
          }} 
        /> : null }
      <FloatButton.Group
        shape="circle"
        open
        icon={<CustomerServiceOutlined />}
        style={{ right: 24 }}
      >
        <Popover title={null} trigger="hover" placement="left" content={<GroupQrCode />}>
          <div className={styles.qrCodeWrapper}>
            <GroupQrCode />
          </div>
        </Popover>
        <FloatButton target="_blank" href="https://github.com/jinchanz/antd-stable-diffusion-webui" icon={<GithubOutlined />} />
        <FloatButton icon={<MailOutlined />} onClick={setShowContactInfo}/>
      </FloatButton.Group>
      <Modal footer={false} title={
        <h1>{t('Contact')}</h1>
      } centered open={showContactInfo} onCancel={setShowContactInfo.bind(null, false)}>
        <div>
          <div className={styles.contactSection} style={{ display: 'flex', flexDirection: 'column', fontSize: 20, fontWeight: 400 }}>
            <span className={styles.contactLabel}>
              {t('Email')} 
            </span>
            <span className={styles.contactContent}>
              june@junemark.tech
            </span>
            <span className={styles.contactContent}>
              mark@junemark.tech
            </span>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default App;