export default {
  /**
   * 素材
   */
  StickerLabel: '标签',
  Use: '使用',
  Home: '首页',
  Design: '设计',
  Train: '模型训练',
  SignIn: '登录',
  SignUp: '免费注册',
  Contact: '联系我们',
  ComingSoon: '奋力研发中，敬请期待~',
  Email: '电子邮箱',
  DigitalTwin: '数字分身',
  Resample: '相似图',
  Rembg: '抠图',
  ToolBox: '工具箱',
  AIPaint: 'AI绘画',
  Profile: '个人资料',
  MyPage: '个人主页',
  Developer: '开发者',
  AIWorkflow: 'AI应用',
  Explore: '探索',
  AIAppsBuilder: 'AI应用构建',
  Member: '会员中心',
  MyWorkflow: '我的应用',
  EditorPick: '精选应用',
  UploadWorkflow: '制作AI应用',
  UserCenter: '个人中心',
  Logout: '退出登录',
  AIBoostArt: 'AI助力艺术',
  BornForDesigner: '为新世代 AI 设计者而生',
  NoWorkflow: '暂无应用',
  TXT2IMG: '文生图',
  openApp: '进入应用',
  editApp: '编辑应用',
  FreeTrial: '限时免费',
  Discount: '限时优惠',
  generate: '立即生成',
  generating: '生成中...',
  generateFailed: '生成失败',
  workflow: {
    app: {
      left: '剩余',
      MCoin: 'M币',
      playground: 'Playground',
      api: 'Open API',
      comingSoon: '敬请期待',
      cost: '本次消耗',
    }
  },
  home: {
    AIWorkflow: 'AI应用',
    AIWorkflowDesc: 'AI应用是一个无需编码即可构建AI应用的工具。',
    AIPainting: 'AI绘画',
    AIPaintingDesc: 'AI 绘画 WebUI，一键保存绘画参数。',
    malette: '摩拉图',
    product: '产品',
    getStarted: '开始使用',
    connect: '关注我们',
    aiPainting: 'AI绘画',
    aiWorkflow: 'AI应用',
    styleFusion: '风格融合',
    stickers: '人像转贴纸',
    tryOn: '模特换装',
    imageTranslate: '漫画汉化',
    pricing: '产品定价',
    Tutorials: '教程',
    FAQ: '常见问题',
    comingSoon: '敬请期待',
    sloganTitle: '运行AI工作流<br></br> 一个<bold>API</bold>就能搞定。',
    appName: '摩拉图',
    textSticker: 'AI表情包',
    aiHair: "AI换发色",
    stickerMaker: "AI贴纸制作",
  },
  loginForm: {
    login: '登录',
    email: '邮箱',
    password: '密码',
    remember: '记住我',
    forget: '忘记密码',
    noAccount: '没有账号？',
    signUp: '免费注册',
    loginFailed: '登录失败',
    welcome: '欢迎来到摩拉图AI工坊',
    signInOrSignUp: '登录/注册',
    sendVerifyCode: '发送验证码',
    sendAgain: '后可发送',
    codeInvalid: '请输入正确的验证码',
    emailInvalid: '请输入正确的邮箱',
    paramInvalid: '请输入正确的邮箱地址和验证码',
    footer: '登录即代表您同意 <term>用户协议</term> 和 <privacy>隐私政策</privacy>，若无账号系统会自动为您创建'
  },
  OpenAPI: {
    APITokenDescription: 'API Token, 在`用户中心 -> 开发者`中获得',
  },
  signAgreementDialog: {
    title: '协议签署',
    content: '请阅读<term>用户协议</term> 和 <privacy>隐私政策</privacy>，点击同意即表示您已阅读并同意，协议将在您使用服务时生效。',
    cancel: '取消',
    agree: '同意',
  },
  PaymentModal: {
    title: '充值提示',
    content: 'M币不足，请充值后使用。',
    cancel: '取消',
    agree: '前往充值',
  },
  ComfyUI: 'ComfyUI'
};
