import { FileData } from "@/models/oss";

export function getServerEnv() {
  return window?.INIT_STATE?.serverEnv;
}

export async function sleep (ms) {
  return new Promise((resolve) => {
      setTimeout(resolve, ms);
  });
}

function createSuperLabel(url, id) {      
  const aTag = document.createElement("a");           
  aTag.setAttribute("href", url);      
  aTag.setAttribute("target", "_blank");      
  aTag.setAttribute("id", id);       
  // 防止反复添加      
  if(!document.getElementById(id)) {                               
      document.body.appendChild(aTag);      
  }      
  aTag.click();    
}

export const newPage = (url) => {
  createSuperLabel(url, Date.now())
};

export async function readFile(file): Promise<FileData> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async function fileReadCompleted() {
      const tmpImg: any = document.createElement('img');
      tmpImg.src = reader.result;
      tmpImg.onload = function () {
        resolve({
          ratio: this.width / this.height,
          width: this.width,
          height: this.height,
          filename: file.name,
          type: file.type,
          src: reader.result,
        });
      };
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function uuid() {
  return crypto.randomUUID().replaceAll('-', '');
}


export const setExpireItemFromLocalStorage = (key: string, value: string, expire: number): void => {
  if (!key || !value) return;
  if (!expire || expire < 0) {
    return localStorage.setItem(key, value);
  }
  const expireDate = Date.now() + expire;
  return localStorage.setItem(key, `${value}|${expireDate}`);
}

export const getExpireItemFromLocalStorage = (key: string): string => {
  if (!key) return '';
  const _value = localStorage.getItem(key);
  if (!_value) return '';
  if (!_value.includes('|')) return _value;
  const parts = _value.split('|');
  return Date.now() > +parts[1] ? '' : parts[0];
}

export const getFilenameFromUrl = (url: string): string => {
  if (!url) return '';
  let _url = url;
  if (_url.includes('?')) {
    _url = _url.split('?')[0];
  }
  if (_url.includes('#')) {
    _url = _url.split('#')[0];
  }
  if (_url.includes('!')) {
    _url = _url.split('!')[0];
  }
  const parts = _url.split('/');
  return parts[parts.length - 1];
};

export const onDownload = async (filename, link) => {
  let img = new window.Image()
  img.setAttribute('crossOrigin', 'Anonymous')
  img.onload = function(){
    let canvas = document.createElement('canvas')
    let context = canvas.getContext('2d')
    canvas.width = img.width
    canvas.height = img.height
    context.drawImage(img, 0, 0, img.width, img.height)
    canvas.toBlob(blob => {
      let url = URL.createObjectURL(blob)
      let a = document.createElement('a')
      let event = new MouseEvent('click')
      a.download = filename || 'default.png'
      a.href = url
      a.dispatchEvent(event)
      URL.revokeObjectURL(url)  // 内存管理,将这句代码注释掉,则将以 blob:http 开头的url复制到浏览器地址栏有效,否则无效.
    })    
  }
  img.src = link + '?v=' + Date.now()
};

export const saveWorkflowInviteCode = (workflowCode, inviteCode: string) => {
  localStorage.setItem(`WorkflowInviteCode_${workflowCode}`, inviteCode);
};

export const getWorkflowInviteCode = (workflowCode) => {
  return localStorage.getItem(`WorkflowInviteCode_${workflowCode}`);
};

export const isChineseDomain = () => {
  return window.location.hostname === 'malette.art';
}