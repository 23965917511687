import { useRoutes } from 'react-router-dom';
import { setHtmlLang } from "@/plugins/utils/i18n";
import { II18nKey } from "@/interface/i18n";
import { antI18n } from "@/plugins/i18n";
import routes from './pages/routes';

import { useEffect, useRef, useState } from 'react';
import { ConfigProvider } from 'antd';
import LoginDialog from './components/LoginDialog';
import { useGlobalStore } from './store';


function App() {
  const locale = useGlobalStore((state) => state.locale);
  const hideLoginModal = useGlobalStore((state) => state.hideLoginModal);
  const disableCancel = useGlobalStore((state) => state.disableCancel);
  const isLoginModalOpen = useGlobalStore((state) => state.isLoginModalOpen);
  const [i18n, setI18n] = useState(antI18n[locale]);
  const canvasContainerRef = useRef(null);

	useEffect(() => {
		setHtmlLang(locale as II18nKey);
		setI18n(antI18n[locale]);
	}, [locale]);
  const config = useRoutes(routes);
  return <ConfigProvider
  locale={i18n}
><div className="App">
    {config}
    <LoginDialog
      closable={!disableCancel}
      onCancel={disableCancel ? () => {} : hideLoginModal}
      onLoginSucceed={hideLoginModal}
      open={isLoginModalOpen}
    />
    <div ref={canvasContainerRef} style={{ display: 'none' }}>
      {/* Canvas will be inserted here */}
    </div>
  </div></ConfigProvider>;
}

export default App;
