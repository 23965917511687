import { useParams } from "react-router-dom";
import ComfyUIPreview from "./preview";


const ComfyUIShare = () => {
  const { publicId } = useParams();
  
  return <div className="w-full h-full">
    <iframe
      title="App Workflow Preview"
      src={`/comfyui/preview?type=comfyuiWorkflow&id=${publicId}`} 
      style={{ width: '100%', height: '100%' }}
    />
  </div>
}

export default ComfyUIShare;