import { getMaletteWorkflowDetail, getWorkflowAppDetail } from "@/services/Workflow";
import { useState, useEffect, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { app } from 'https://i.ablula.tech/awe/comfy/web/scripts/app.js';
import { uploadFileToOSS } from "@/utils/file";
import styles from './index.module.scss';
import { Button, message, Popconfirm, Spin } from "antd";
import { OssTOken } from "@/models/oss";
import { useGlobalStore } from "@/store";
import { getProfileData } from "@/services/user";
import { Profile } from "@/models/common/user";
import { getSdTask } from "@/services/SdFile";
import { MaletteWorkflowItem } from "@/constants";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import PaymentModal from "@/components/PaymentDialog";
import SignAgreementDialog from "@/components/SignAgreementDialog";

const loadComfyUIWorkflow = async (type, workflow) => {
  const workflowContent = type === 'aiAppWorkflow' ? workflow?.contentTpl : workflow?.content;
  if (!workflowContent) {
    console.error('工作流数据为空');
    return;
  }
  const jsonPreviewData = typeof workflowContent === 'string' ? JSON.parse(workflowContent) : workflowContent;
  if (!jsonPreviewData) {
    console.error('工作流数据为空: ', workflowContent);
    return;
  }
  if (jsonPreviewData?.nodes?.length) {
    app.loadGraphData(jsonPreviewData);
  } else {
    app.loadApiJson(jsonPreviewData);
  }
  // 如果当前是在 iframe 中，则向外 postMessage
  if (window.parent) {
    const { output: apiJson } = await app.graphToPrompt();
    window.parent.postMessage({
      type: 'comfyuiWorkflowLoaded',
      data: {
        publicId: workflow?.publicId,
        type: type,
        apiJson: apiJson
      }
    }, '*');
  } else {
    console.log('comfyuiWorkflowLoaded: ', workflow?.publicId);
  }
}

const ComfyUIPreview = () => {
  // 获取 url query 参数
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const type = searchParams.get('type');
  const isPreview = searchParams.get('isPreview')

  const [running, setRunning] = useState(false);
  const [queueSize, setQueueSize] = useState(0);
  const [appLoaded, setAppLoaded] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [profileData, setProfileData] = useState<Profile>({});
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const currentUser = useGlobalStore((state) => state.currentUser);
  const [ossToken, setOssToken] = useState<OssTOken | null>(null);
  const showLoginModal = useGlobalStore((state) => state.showLoginModal);
  const showSignAgreementModal = useGlobalStore((state) => state.showSignAgreementModal);
  const isSignAgreementModalOpen = useGlobalStore((state) => state.isSignAgreementModalOpen);
  const hideSignAgreementModal = useGlobalStore((state) => state.hideSignAgreementModal);
  const showPaymentModal = useGlobalStore((state) => state.showPaymentModal);
  const isPaymentModalOpen = useGlobalStore((state) => state.isPaymentModalOpen);
  const hidePaymentModal = useGlobalStore((state) => state.hidePaymentModal);
  const [ currentWorkflow, setCurrentWorkflow ] = useState<MaletteWorkflowItem>();
  
  const init = useCallback(async () => {
    try {
      const tokenResponse = await fetch('/api/v1/oss/token');
      if (!tokenResponse || tokenResponse.status !== 200) {
        messageApi.error('获取 OSS Token 失败');
        return;
      }
      const tokenResp = await tokenResponse.json();
      if (tokenResp?.success) {
        const initOssToken: OssTOken = tokenResp.data;
        setOssToken(initOssToken);
      } else if (tokenResp?.code === 401) {
        showLoginModal(true);
        return;
      }
      const profileResp = await getProfileData();
      if (profileResp?.data) {
        setProfileData(profileResp.data);
      } else {
        messageApi.error('获取用户信息失败');
      }
    } catch (error) {
      messageApi.error(error);
    }
  }, [messageApi, showLoginModal]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    const token = localStorage.getItem('ossToken');
    if (token) {
      setOssToken(JSON.parse(token));
    }
  }, []);

  useEffect(() => {
    if (!id || !type) {
      return;
    }
    async function setComfyUIData() {
      const res = await getMaletteWorkflowDetail(id);
      setCurrentWorkflow(res.data);
    }

    async function setWorkflowAppData() {
      const res = await getWorkflowAppDetail(id);
      setCurrentWorkflow(res.data);
    }

    if (type === 'comfyuiWorkflow') {
      setComfyUIData();
    } else if (type === 'aiAppWorkflow') {
      setWorkflowAppData();
    }

  }, [id, type]);

  useEffect(() => {
    if (!ossToken || !canvasContainerRef?.current || !profileData) return;
    const onUpload = async (file: File) => {
      try {
        return await uploadFileToOSS(file, ossToken);
      } catch (error) {
        message.error(`解析工作流失败: ${error.message}`);
      }
      return false;
    }
    const onQueueSizeChange = (size: number) => {
      console.log('queue size', size);
      setQueueSize(size);
    }
    if (!appLoaded) {
      const handle = setInterval(() => {
        if (app.canvasEl && canvasContainerRef.current) {
          app.container = canvasContainerRef.current;
          app.onUpload = onUpload;
          app.onQueueSizeChange = onQueueSizeChange;
          canvasContainerRef.current.appendChild(app.canvasEl);
          app.resizeCanvas();
          setTimeout(() => {
            loadComfyUIWorkflow(type, currentWorkflow);
            setAppLoaded(true);
          }, 500);
          clearInterval(handle);
        }
      }, 1000);
    }
  }, [ossToken, canvasContainerRef, appLoaded, currentWorkflow, type]);


  // 循环获取执行结果
  const loopFetchResult = async (response) => {
    if (!response?.task?.publicId) {
      messageApi.error('执行失败');
      return;
    }
    const { task } = response;
    const taskResponse = await getSdTask(task.publicId);
    if (taskResponse?.data?.status === 'SUCCEED') {
      messageApi.success('执行成功');
      setRunning(false);
    } else if (taskResponse?.data?.status === 'FAILED') {
      messageApi.error('执行失败');
      setRunning(false);
    } else {
      setTimeout(() => {
        loopFetchResult(response);
      }, 2000);
    }
  }

  const handleQueuePrompt = async (ignoreSignAgreement) => {
    if (!currentUser?.publicId) {
      showLoginModal(false);
      return;
    }
    if (!ignoreSignAgreement && !currentUser?.signAgreementAt) {
      showSignAgreementModal()
      return;
    }

    // 判断 M 币是否足够，不足则弹出支付框
    const balance = +(profileData?.mcoinAccount?.balance || 0);
    // 暂时统一扣 10 M币
    const workflowCost = 10;
    if (balance < workflowCost) {
      showPaymentModal();
      return;
    }
    setRunning(true);
    const response = await app.queuePrompt();

    const profileResp = await getProfileData();
    setProfileData(profileResp.data);
    loopFetchResult(response);
  }

  return <div className="w-full h-full flex flex-col">
    {contextHolder}
    { !isPreview && <div className="flex flex-row justify-between items-center gap-2 bg-white p-4 rounded-md z-10">
      <div className="flex flex-row gap-2">
        <div className="w-16 h-16">
          <img 
            src={currentWorkflow?.cover} 
            alt={currentWorkflow?.name} 
            className="w-16 h-16 rounded-md"
          />
        </div>
        <div className="flex-auto flex flex-col gap-2">
          <span className="text-xl font-bold text-ellipsis text-black">
            { currentWorkflow?.name }
          </span>
          <span className="text-sm text-gray-500">
            { currentWorkflow?.description }
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Button
          type="primary"
          loading={running}
          onClick={handleQueuePrompt?.bind(null, false)}
        >
          执行
          <span>(排队{ queueSize })</span>
        </Button>
      </div>
    </div> }
    <div className={styles.canvasPanel}>
        <div ref={canvasContainerRef} style={{ 
          position: 'fixed', 
          top: 0, 
          left: 0,
          width: '100%',
          height: '100%',
        }}>
          <Spin spinning={!appLoaded} size="large" fullscreen>
          {/* Canvas will be inserted here */}
          </Spin>
        </div>
    </div>
    <SignAgreementDialog
      onCancel={hideSignAgreementModal}
      open={isSignAgreementModalOpen}
      onOk={handleQueuePrompt?.bind(null, true)}
    />
    <PaymentModal
      onCancel={hidePaymentModal}
      open={isPaymentModalOpen}
    />
  </div>
}

export default ComfyUIPreview;