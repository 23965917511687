import moment from "moment";
import Dragger from "antd/es/upload/Dragger";
import { CloseOutlined, InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { message, UploadProps, Spin, Button, Upload, Image as ImageComp } from "antd";
import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { uuid } from "@/utils";
import { OssTOken } from "@/models/oss";

import styles from './index.module.scss';

export const MAX_FILE_SIZE_LIMIT = 10 * 1024 * 1024;

async function getFileWidthAndHeight (file) {
  return new Promise((resolve) => {
    const _URL = window.URL || window.webkitURL
    const img = new Image()
    img.onload = function () {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
    img.src = URL.createObjectURL(file)
  });
  
}

export default function ImageUploader(props: { 
  onUploaded: (file: unknown) => void, 
  multiple?: boolean,
  uploadDescription?: string,
  uploadIcon?: ReactNode,
  defaultFileList?: unknown,
}) {
  const { 
    onUploaded, 
    uploadIcon,
    uploadDescription = 'Click or drag image to this area to upload', 
    ...propsUploadProps 
  } = props;
  const [spinning, setSpinning] = useState(false);
  const [ossToken, setOssToken] = useState<OssTOken>();
  const [messageApi, contextHolder] = message.useMessage();
  const init = useCallback(async () => {
    try {
      const tokenResponse = await fetch('/api/v1/oss/token');
      const initOssToken: OssTOken = (await tokenResponse.json()).data;
      setOssToken(initOssToken);
    } catch (error) {
      messageApi.error(error);
    }
  }, [messageApi]);

  useEffect(() => {
    init();
  }, [init]);
  
  const beforeUpload = async (file) => {
    if (file.size > MAX_FILE_SIZE_LIMIT) {
      messageApi.error(`文件超过限制 ${MAX_FILE_SIZE_LIMIT/(1024*1024)}MB`);
      return;
    }
    
    setSpinning(true);
    if (!ossToken) return false;

    const expire = Number(ossToken.expire) * 1000;

    if (expire < Date.now()) {
      await init();
    }

    const now = moment(new Date());

    file.url = `sd-online/images/${now.format('YYYY/MM/DD')}/${uuid()}${file.name.slice(file.name.lastIndexOf('.'))}`;
    return file;
  };

  const getExtraData: UploadProps['data'] = (file) => ({
    key: file.url,
    OSSAccessKeyId: ossToken?.accessid,
    policy: ossToken?.policy,
    Signature: ossToken?.signature,
  });

  const handleLoRAUploaded = useCallback(async (info) => {
    const { status } = info.file;
    console.log('status: ', status);
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'removed') {
      onUploaded?.({
        fileList: info.fileList
      })
    }
    if (status === 'error') {
      messageApi.error(`${info.file.name} file upload failed.`);
      return;
    }
    // 上传成功后，
    if (status === 'done') {
      const imgInfo = await getFileWidthAndHeight(info.file.originFileObj);
      try {
        onUploaded?.({
          ...info.file,
          ...imgInfo,
          fileList: info.fileList
        })
      } catch (e) {
        console.error(`出现问题: ${e?.message}`);
        console.log('e instanceof Error: ', e instanceof Error)
        if (e instanceof Error) {
          messageApi.error(`出现问题: ${e?.message}`)
        }
      } finally {
        setSpinning(false);
      }
    }
  }, [messageApi, onUploaded])

  const uploadProps: UploadProps = {
    name: 'file',
    accept: '.jpg,.png,.jpeg',
    action: ossToken?.host,
    beforeUpload,
    data: getExtraData,
    onChange: handleLoRAUploaded,
    listType:"picture-card",
    itemRender: (node, file, _, actions) => {
      let imgUrl;
      if (file?.thumbUrl) {
        imgUrl = file.thumbUrl;
      } else if (!file.url?.startsWith('http')) {
        imgUrl = `https://i.ablula.tech/${file.url}!medium`;
      } else {
        imgUrl = file.url;
      }
      return <div className={styles.imageCard}>
        <ImageComp wrapperClassName={styles.imageWrapper} src={imgUrl} />
        <Button size="small" className={styles.closeBtn} onClick={actions?.remove}  type='text'><CloseOutlined /></Button>
      </div>
    }
  };
  return (
    <div style={{ height: '100%' }}>
      {contextHolder}
      <Upload
        className={styles.imageUploader}
        {...uploadProps}
        {...(propsUploadProps || {})}
      >
        <p className="ant-upload-drag-icon">
          { uploadIcon ? uploadIcon : <InboxOutlined /> }
        </p>
        <p className="ant-upload-text">{uploadDescription}</p>
      </Upload>
    </div>
  );
}
