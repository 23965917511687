
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const RandomSvg = () => (
  <svg t="1709411606329" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4292" width="28" height="28" fill='#999999'><path d="M787.456 442.368h-18.944v41.984h27.136V450.56c0-4.608-3.584-8.192-8.192-8.192zM512 0z" p-id="4293"></path><path d="M512 0C229.376 0 0.512 229.376 0.512 512S229.376 1024 512 1024s511.488-228.864 511.488-512c0-282.624-228.864-512-511.488-512z m342.528 414.72c0-14.336 11.776-26.112 26.112-26.112s26.112 11.776 26.112 26.112-11.776 26.112-26.112 26.112h-26.112V414.72zM394.24 460.8l44.032-88.576h51.712l-36.864 70.656c-1.536 3.584 0.512 7.168 4.608 7.168h3.072l3.072-6.144h51.712l-44.544 87.04c-1.536 3.584 0.512 7.168 4.608 7.168h29.696l-23.552 43.008H421.888c-25.6-1.024-9.728-32.256-9.728-32.256l27.136-55.808h-35.84C378.368 492.032 394.24 460.8 394.24 460.8z m-248.32 162.816l-29.696-54.272s17.408-54.272 12.8-125.952H184.32s0.512 163.328-38.4 180.224z m126.976-11.776s-1.536 40.448-36.864 40.448h-17.408s-34.304-3.584-34.304-44.032h34.816V371.712h53.76v240.128z m34.816-168.448h54.784c-4.608 71.68 12.8 125.952 12.8 125.952l-29.696 54.272c-38.4-16.896-37.888-180.224-37.888-180.224z m79.872 208.896c-9.216 0-13.312-6.144-13.312-6.144l26.624-51.2c4.608 4.608 10.752 3.584 10.752 3.584h70.144l-28.16 53.76H387.584z m275.968-0.512H481.792l25.6-51.712h51.2V443.392h-34.304V388.608h121.856v54.784h-33.792v156.672h51.712v51.712z m198.656 0h-13.824c-25.088 0-45.056-19.968-45.056-45.056h50.176v-56.32c0-7.168-5.632-12.8-12.8-12.8h-72.192v114.176h-52.736V537.6h-52.736V483.84h52.736v-41.984h-33.28V388.608h33.28v-16.896h52.736v16.896h20.48c33.28 0 59.904 26.624 59.904 59.904v35.328h6.144c28.672 0 51.712 23.04 51.712 51.712v71.168c0.512 25.088-19.456 45.056-44.544 45.056z" p-id="4294"></path></svg>
);

export const XiaohongshuIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={RandomSvg} {...props} />
);