import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Upload, message, Empty, Modal, Form, Input, Popconfirm, Divider } from "antd";

import { app } from 'https://i.ablula.tech/awe/comfy/web/scripts/app.js';

import styles from './index.module.scss';
import { FieldData, MaletteWorkflowItem } from "@/constants";
import { listMaletteWorkflows, createOrUpdateWorkflow, deleteWorkflow } from "@/services/Workflow";
import { useGlobalStore } from "@/store";
import { useTranslation } from "react-i18next";
import { OssTOken } from "@/models/oss";
import { uploadFileToOSS } from "@/utils/file";
import SignAgreementDialog from "@/components/SignAgreementDialog";
import PaymentModal from "@/components/PaymentDialog";
import { getProfileData } from "@/services/user";
import { Profile } from "@/models/common/user";
import WorkflowList from "@/components/WorkflowList";
import LeftPanel from "../../components/LeftPanel";
import AvatarUploader from "@/components/AvatarUploader";
import { DeleteOutlined, PlusOutlined, SaveOutlined, CloudUploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { getSdTask } from "@/services/SdFile";

app.setup({
  appendChild: () => {},
}, () => {}, () => {});

window.app = app;

const CanvasComponent = () => {
  const [ t ] = useTranslation();
  const navigate = useNavigate();
  const { publicId } = useParams();
  const currentUser = useGlobalStore((state) => state.currentUser);
  const canvasContainerRef = useRef(null);
  const [ maletteWorkflowList, setMaletteWorkflowList ] = useState<MaletteWorkflowItem[]>([]);
  const [showSaveModal, setShowSaveModal] = useState(false);
  
  const [ossToken, setOssToken] = useState<OssTOken>();
  const [messageApi, contextHolder] = message.useMessage();
  const [queueSize, setQueueSize] = useState(0);
  const showLoginModal = useGlobalStore((state) => state.showLoginModal);
  const showSignAgreementModal = useGlobalStore((state) => state.showSignAgreementModal);
  const isSignAgreementModalOpen = useGlobalStore((state) => state.isSignAgreementModalOpen);
  const hideSignAgreementModal = useGlobalStore((state) => state.hideSignAgreementModal);
  const showPaymentModal = useGlobalStore((state) => state.showPaymentModal);
  const isPaymentModalOpen = useGlobalStore((state) => state.isPaymentModalOpen);
  const hidePaymentModal = useGlobalStore((state) => state.hidePaymentModal);
  const [profileData, setProfileData] = useState<Profile>({});
  const [ currentWorkflow, setCurrentWorkflow ] = useState<MaletteWorkflowItem>();
  const [ form ] = Form.useForm();
  const [formFields, setFormFields] = useState<FieldData[]>();
  const [ appLoaded, setAppLoaded ] = useState(false);
  const [ running, setRunning ] = useState(false);
  const [ saveLoading, setSaveLoading ] = useState(false);

  const init = useCallback(async () => {
    try {
      const tokenResponse = await fetch('/api/v1/oss/token');
      if (!tokenResponse || tokenResponse.status !== 200) {
        messageApi.error('获取 OSS Token 失败');
        return;
      }
      const tokenResp = await tokenResponse.json();
      if (tokenResp?.success) {
        const initOssToken: OssTOken = tokenResp.data;
        setOssToken(initOssToken);
      } else if (tokenResp?.code === 401) {
        showLoginModal(true);
        return;
      }
      const profileResp = await getProfileData();
      if (profileResp?.data) {
        setProfileData(profileResp.data);
      } else {
        messageApi.error('获取用户信息失败');
      }
    } catch (error) {
      messageApi.error(error);
    }
  }, [messageApi, showLoginModal]);

  useEffect(() => {
    init();
  }, [init, currentUser]);

  useEffect(() => {
    if (!ossToken || !canvasContainerRef?.current) return;
    const onUpload = async (file: File) => {
      try {
        return await uploadFileToOSS(file, ossToken);
      } catch (error) {
        message.error(`解析工作流失败: ${error.message}`);
      }
      return false;
    }
    const onQueueSizeChange = (size: number) => {
      setQueueSize(size);
    }
    if (!appLoaded) {
      const handle = setInterval(() => {
        if (app.canvasEl && canvasContainerRef.current) {
          app.container = canvasContainerRef.current;
          app.onUpload = onUpload;
          app.onQueueSizeChange = onQueueSizeChange;
          canvasContainerRef.current.appendChild(app.canvasEl);
          app.resizeCanvas();
          setAppLoaded(true);
          clearInterval(handle);
        }
      }, 1000);
    }
  }, [ossToken, canvasContainerRef]);

  const saveMaletteWorkflow = async () => {
    try {
      setSaveLoading(true);
      const values = await form.validateFields();
      const promptRes = await app.graphToPrompt();
      const { workflow } = promptRes;
      const data: MaletteWorkflowItem = {
        name: values.name,
        description: values.description,
        content: workflow,
        cover: values.cover,
      };

      if (currentWorkflow) {
        data.publicId = currentWorkflow.publicId;
      }

      const response = await createOrUpdateWorkflow(data);
      setSaveLoading(false);
      if (response.success) {
        messageApi.success('保存成功');
        fetchMaletteWorkflowList();
        setShowSaveModal(false);
        navigate(`/comfyui/${data.publicId || response?.data?.publicId || ''}`);
      } else {
        messageApi.error('保存失败');
      }
    } catch (error) {
      console.error(error);
      setSaveLoading(false);
      messageApi.error('保存失败');
    }
  }

  const fetchMaletteWorkflowList = async () => {
    try {
      const response = await listMaletteWorkflows({ isDefault: true});
      const _workflowList = response.data;
      setMaletteWorkflowList((_workflowList || []).reverse());
      if (!publicId && _workflowList?.length) {
        navigate(`/comfyui/${_workflowList[0].publicId}`);
      }
    } catch (error) {
      messageApi.error('获取工作流列表失败');
    }
  }

  const handleWorkflowUpload = async (file: File) => {
    try {
      await app.handleFile(file);
    } catch (error) {
      message.error(`解析工作流失败: ${error.message}`);
    }
    return false;
  };
  
  useEffect(() => {
    fetchMaletteWorkflowList();
  }, [currentUser]);

  useEffect(() => {
    if (!publicId || !maletteWorkflowList?.length) return;
    if (publicId === 'new') {
      if (appLoaded) {
        app.clean();
        app.graph.clear();
        app.loadGraphData();
      }
      setFormFields([]);
      return;
    }
    const workflow = maletteWorkflowList.find((item) => item.publicId === publicId);
    if (!workflow) return;
    if (appLoaded) {
      app.loadGraphData(workflow.content);
    }
    if (publicId === currentWorkflow?.publicId) return;
    setCurrentWorkflow(workflow);
  }, [publicId, maletteWorkflowList, appLoaded]);

  useEffect(() => {
    if (!currentWorkflow) {
      setFormFields([]);
      form.resetFields();
    } else {
      setFormFields([
        {
          name: 'name',
          value: currentWorkflow.name,
        },
        {
          name: 'description',
          value: currentWorkflow.description,
        },
        {
          name: 'cover',
          value: currentWorkflow.cover,
        }
      ]);
      form.setFieldsValue({
        name: currentWorkflow.name,
        description: currentWorkflow.description,
        cover: currentWorkflow.cover,
      });
    }
  }, [currentWorkflow]);

  const handleSelectWorkflow = (workflow: MaletteWorkflowItem) => {
    navigate(`/comfyui/${workflow.publicId}`);
  }

  // 循环获取执行结果
  const loopFetchResult = async (response) => {
    if (!response?.task?.publicId) {
      messageApi.error('执行失败');
      return;
    }
    const { task } = response;
    const taskResponse = await getSdTask(task.publicId);
    if (taskResponse?.data?.stage === 'FINISHED') {
      messageApi.success('执行成功');
      setRunning(false);
    } else if (taskResponse?.data?.stage === 'FAILED') {
      messageApi.error('执行失败');
      setRunning(false);
    } else {
      setTimeout(() => {
        loopFetchResult(response);
      }, 2000);
    }
  }

  const handleQueuePrompt = async (ignoreSignAgreement) => {
    if (!currentUser?.publicId) {
      showLoginModal(false);
      return;
    }
    if (!ignoreSignAgreement && !currentUser?.signAgreementAt) {
      showSignAgreementModal()
      return;
    }
    // 如果是现存工作流，则先保存
    if (currentWorkflow?.publicId) {
      await saveMaletteWorkflow();
    }

    // 判断 M 币是否足够，不足则弹出支付框
    const balance = +(profileData?.mcoinAccount?.balance || 0);
    // 暂时统一扣 10 M币
    const workflowCost = 10;
    if (balance < workflowCost) {
      showPaymentModal();
      return;
    }
    setRunning(true);
    const response = await app.queuePrompt();

    const profileResp = await getProfileData();
    setProfileData(profileResp.data);
    loopFetchResult(response);
  }

  const handleSaveWorkflow = () => {
    setShowSaveModal(true);
  }

  const downloadWorkflow = () => {
    let filename = "workflow.json";
    filename = prompt("Save workflow as:", filename);
    if (!filename) return;
    if (!filename.toLowerCase().endsWith(".json")) {
      filename += ".json";
    }
    app.graphToPrompt().then(p=>{
      const json = JSON.stringify(p.workflow, null, 2); // convert the data to a JSON string
      const blob = new Blob([json], {type: "application/json"});
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        a.remove();
        window.URL.revokeObjectURL(url);
      }, 0);
    });
  }

  const createNewMaletteWorkflow = () => {
    setCurrentWorkflow(undefined);
    navigate('/comfyui/new');
    setTimeout(() => {
      setShowSaveModal(true);
    }, 300);
  }

  const deleteCurrentWorkflow = async () => {
    if (!currentWorkflow?.publicId) return;
    const response = await deleteWorkflow(currentWorkflow.publicId);
    if (response.success) {
      messageApi.success('删除成功');
      fetchMaletteWorkflowList();
      app.clean();
      app.graph.clear();
      navigate('/comfyui/new');
    } else {
      messageApi.error('删除失败');
    }
  }

  // 渲染一个空的 div 容器，canvas 会被插入其中
  return (
    <div className={styles.comfyui}>
      {contextHolder}
      <LeftPanel>
        <div className="pb-4 px-1">
          {/* 操作区，垂直布局 */}
          <div className="pt-4 flex flex-col gap-1 justify-between items-center mb-4 px-1 bg-white">
            <Upload
                className={styles.uploadButton}
                disabled={!currentUser?.publicId}
                accept=".json,.jpeg,.png,.webp"
                itemRender={() => null}
                beforeUpload={handleWorkflowUpload} 
              >
                <Button 
                  style={{ width: 270 }}
                  type="dashed" 
                  disabled={!currentUser?.publicId}>
                  <CloudUploadOutlined /> 上传工作流
                </Button>
              </Upload>
              <span className="flex w-full">
                <Button
                  style={{ width: '100%' }}
                  type="primary" 
                  onClick={createNewMaletteWorkflow}>
                  <PlusOutlined /> 新建工作流
                </Button>
              </span>
          </div>
          <Divider />
          <div style={{ height: 'calc(100vh - 213px)', 'overflowY': 'scroll' }}>
            {
              maletteWorkflowList?.length 
              ? <WorkflowList 
                  selectedItemId={currentWorkflow?.publicId} 
                  items={maletteWorkflowList} 
                  onSelected={handleSelectWorkflow} 
                />
              : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无工作流">
                  <Button type="primary" onClick={createNewMaletteWorkflow}>新建工作流</Button>
                </Empty>
            }
          </div>
        </div>
      </LeftPanel>
      <div className={styles.mainPanel}>
        <div className={styles.actionPanel}>
          <div className={styles.action}></div>
          <div className={styles.action}></div>
          <div className={styles.action}>
            <Button type="default" onClick={handleSaveWorkflow}>
              <SaveOutlined />
            </Button>
            <Button type="default" onClick={downloadWorkflow}>
              <DownloadOutlined />
            </Button>
            <Popconfirm title="是否删除当前工作流" onConfirm={deleteCurrentWorkflow}>
              <Button type="default" disabled={!currentWorkflow?.publicId}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
            <Button type="primary" onClick={
              () => {
                app.clean();
                app.graph.clear();
                app.loadGraphData();
              }
            } disabled={running}>
              重置
            </Button>
            <Button
              type="primary"
              loading={running}
              onClick={handleQueuePrompt?.bind(null, false)}
            >
              执行
              <span>(排队{ queueSize })</span>
            </Button>
          </div>
        </div>
        <div className={styles.canvasPanel}>
          <div ref={canvasContainerRef} style={{ 
            position: 'fixed', 
            top: 0, 
            left: 0,
            width: '100%',
            height: '100%',
          }}>
            {/* Canvas will be inserted here */}
          </div>
        </div>
      </div>
      <div className={styles.rightPanel}>
        {/* Your content for the right panel goes here */}
      </div>
      <SignAgreementDialog
        onCancel={hideSignAgreementModal}
        open={isSignAgreementModalOpen}
        onOk={handleQueuePrompt?.bind(null, true)}
      />
      <PaymentModal
        onCancel={hidePaymentModal}
        open={isPaymentModalOpen}
      />
      <Modal
        title="保存/新建工作流"
        open={showSaveModal}
        footer={false}
        onCancel={setShowSaveModal.bind(null, false)}
      >
        <Form form={form} fields={formFields} layout="vertical">
          <Form.Item label="工作流名称" name="name" rules={[{ required: true }]}>
            <Input type="text" />
          </Form.Item>
          <Form.Item label="工作流描述" name="description" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="cover" label="封面图" rules={[{ required: true }]}>
            <AvatarUploader enableCrop />
          </Form.Item>
          {/* 右小角，取消和确认按钮 */}
          <div className="flex justify-end gap-2">
            <Button onClick={setShowSaveModal.bind(null, false)}>取消</Button>
            <Button loading={saveLoading} type="primary" onClick={saveMaletteWorkflow}>确认</Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default CanvasComponent;

export { app };