import { CommonResponse } from '@/models/common/response';
import { SdTaskResult } from '@/models/sd/SdFile';
import request from '@/utils/request';

export async function listWorksPaged(type, pageNo = 1, pageSize = 20): Promise<CommonResponse<SdTaskResult[]>> {
  const response = await request
    .get<string, CommonResponse<SdTaskResult[]>>(
      `/api/v1/sd/task-results/paged?pageNo=${pageNo}&pageSize=${pageSize}${type ? `&type=${type}` : ''}`
    );
  return response;
}


export async function listWorks({ type, unfinished }): Promise<CommonResponse<SdTaskResult[]>> {
  let url = `/api/v1/sd/tasks?type=${type}`;
  if (unfinished !== undefined) {
    url += `&unfinished=${!!unfinished}`;
  }
  const response = await request.get<string, CommonResponse<SdTaskResult[]>>(url);
  return response;
}