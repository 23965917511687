import { ParamNode } from "@/models/sd/comfy";
import { Form, Input, FormInstance, Switch, Select, Button, Image, Badge, message } from "antd";
import InputSlider from "@/components/InputSlider";
import CommonUploader from "@/components/CommonUploader";
import { WEIGHT_KEYS } from "@/constants/ComfyUI";
import { RandomIcon } from '@/components/Icon/RandomIcon';
import { LoadingOutlined, InboxOutlined, CloseOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import form from "antd/es/form";
import { t, use } from "i18next";
import workflow from "../..";
import { FieldData, WorkflowItem } from "@/constants";
import { Profile } from "@/models/common/user";
import { useEffect, useState } from "react";
import { SdTaskResult } from "@/models/sd/SdFile";

export interface IParamRenderProps {
  param?: Record<string, ParamNode | any>,
  form: FormInstance,
}


const UploadButton = (loading) => {
  return (
  <button style={{ border: 0, background: 'none', height: 222 }} type="button">
    {loading 
      ? <LoadingOutlined /> 
      : <>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag image to this area to upload</p>
      </>
    }
  </button>
)};

const PreviewComponent = (value, clear) => {
  return <div className={styles.imgWrapper}>
    <Image height={252} style={{objectFit: 'contain'}} src={value} /> 
    <Button className={styles.closeBtn} onClick={clear} type='text'><CloseOutlined /></Button>
  </div>
}

const SeedInput = (props) => {
  const { value, ...otherProps } = props;
  return <div className={styles.SeedInput}>
    <Input value={value} {...otherProps} />
    <Button onClick={() => {
      otherProps?.onChange?.(Math.floor(Math.random() * 100000000000000));
    }}><RandomIcon /></Button>
  </div>
};

const ParamRender = (props: IParamRenderProps) => {
  const { param, form } = props;
  
  if (!param) return null;

  return <>
      {Object.keys(param).filter((key) => {
        const node = param[key];
        return node.paramType !== 'TODO';
      }).map((key) => {
        const node = param[key];
        let compInstance = <Input  />;
        switch (node.paramType) {
          case 'INT':
          case 'FLOAT':
          case 'Number':
            let min, max, defaultValue;
            if (node.paramConfig?.max === 0) {
              max = 0;
            } else {
              max = node.paramConfig?.max || node?.max || 2048;
            }
            if (node.paramConfig?.min === 0) {
              min = 0;
            } else {
              min = node.paramConfig?.min || node?.min || 512;
            }
            defaultValue = node.paramConfig?.default || node?.defaultValue;
            compInstance = <InputSlider 
              max={max}
              min={min}
              step={node.paramConfig?.step || node?.step}
              defaultValue={defaultValue}
            />;
            break;
          case 'String':
            if (node.paramConfig?.multiple) {
              compInstance = <Input.TextArea />;
            } else {
              compInstance = <Input />;
            }
            break;
          case 'Image':
            compInstance = <CommonUploader
              imageStyle="!medium"
              accept={'.jpg,.png,.heic,.webp,.gif,.jpeg'}
              previewComponent={PreviewComponent}
              uploadButton={UploadButton}/>;
            break;
          case 'Boolean':
            compInstance = <Switch
              checked={node.paramConfig?.default || node?.defaultValue}
            />;
            break;
          case 'Array':
            compInstance = <Select
              options={(node.paramConfig?.options || node?.options || []).map(item => {
                return {
                  label: item,
                  value: item,
                }
              })}
            />;
            break;
        }
        // 对一些 key 特殊处理
        if (["seed", "noise_seed", "rand_seed"].includes(node.paramKey)) {
          compInstance = <SeedInput form={form} />;
        } else if (node.paramKey === 'prompt' || node.paramKey === 'text') {
          compInstance = <Input.TextArea />;
        } else if (['batch_size', 'amount'].includes(node.paramKey)) {
          compInstance = <InputSlider 
            max={node.max || 8}
            min={node.min || 1}
            step={node.paramConfig?.step || node?.step || 1}
          />;
        } else if (node.paramKey === 'scale_by') {
          compInstance = <InputSlider 
            max={node.max || 4}
            min={node.min || 1}
            step={node.paramConfig?.step || node?.step}
          />;
        } else if (node.paramKey === 'steps') {
          compInstance = <InputSlider 
            max={node.max || 40}
            min={node.min || 1}
            step={node.paramConfig?.step || node?.step}
          />;
        } else if (WEIGHT_KEYS.includes(node.paramKey || '')) {
          compInstance = <InputSlider 
            max={node.max || 1}
            min={node.min || 0}
            step={node.paramConfig?.step || node?.step || 0.01}
          />;
        } else if ([ 'top', 'bottom', 'left', 'right' ].includes(node.paramKey)) {
          compInstance = <InputSlider 
            max={node.max || 512}
            min={node.min || 0}
            step={node.paramConfig?.step || node?.step || 1}
          />;
        } else if ([
            'width',
            'height',
            'empty_lantent_width',
            'empty_lantent_height',
          ].includes(node.paramKey)) {
          compInstance = <InputSlider 
            max={2048}
            min={375}
            defaultValue={node.paramConfig?.default || node?.defaultValue || 512}
            step={node.paramConfig?.step || node?.step || 1}
          />;
        }  else if (node.paramKey === 'cfg') {
          compInstance = <InputSlider 
            max={node.max || 10}
            min={node.min || 1}
            step={0.01}
            
          />;
        }

        const notRequired = node.notRequired === 'Y';
        return <div key={key}  className={styles.paramItem}><Form.Item 
          key={key} 
          label={node.name || node.paramKey || node.key || key} 
          name={node.key || key}
          tooltip={node.description || node.paramKey}
          rules={[{ required: !notRequired, message: `请输入${node.name || node.paramKey}` }]}
        >
          {compInstance}
        </Form.Item></div>
      })}
  </>
}

export interface IParamContainerProps {
  workflow: WorkflowItem;
  onGenerate: (param: any) => void;
  currentWorks?: SdTaskResult;
  code?: string;
  isMobilePortrait?: boolean;
  profileData?: Profile;
}

const ParamContainer = (props: IParamContainerProps) => {
  const { workflow, onGenerate, currentWorks, code, isMobilePortrait, profileData:propsProfileData } = props;
  const [form] = Form.useForm();
  const [generating, setGenerating] = useState(false);
  const [profileData, setProfileData] = useState<Profile>(propsProfileData);
  const [formFields, setFormFields] = useState<FieldData[]>();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (!propsProfileData) return;
    setProfileData(propsProfileData);
  }, [propsProfileData]);

  useEffect(() => {
    if (workflow?.paramTpl) {
      setFormFields(Object.keys(workflow?.paramTpl).filter((key) => {
        const node: any = workflow?.paramTpl?.[key];
        return node?.paramType !== 'TODO';
      }).map((key) => {
        return {
          name: key,
          value: workflow?.paramTpl?.[key]?.defaultValue
        }
      }));
    }
  }, [workflow]);

  useEffect(() => {
    if (!currentWorks) {
      setFormFields([])
    } else {
      const params = currentWorks?.params?.params;
      if (!params) return;
      const parsedFieldDataList: FieldData[] = [
        ...Object.keys(params).map((key) => {
          return {
            name: key,
            value: params[key]
          }
        })
      ];
      setFormFields(parsedFieldDataList);
    }
  }, [currentWorks]);

  const handleGenerate = async () => {
    try {
      const res = await form.validateFields();
    } catch (e) {
      messageApi.error('请输入所有必填项');
      return;
    }
    const updates = form.getFieldsValue();
    const param = {
      workflowCode: code,
      params: {
        ...updates
      }
    };
    onGenerate?.(param);
  } 

  return <div className={isMobilePortrait ? styles.mobilePortrait : "h-[calc(100vh-64px)] overflow-y-auto py-4 flex flex-col gap-4"}>
    {contextHolder}
    <div className="flex flex-col w-full">
      <span className="text-2xl">{workflow?.name}</span>
      <span>{workflow?.description || 'no description'}</span>
    </div>
    <Form className={isMobilePortrait ? styles.mobilePortrait : "h-[calc(100vh-200px)] overflow-y-auto rounded-md"} layout="vertical" form={form} fields={formFields} >
      <div className={styles.paramItem}>
        <ParamRender param={workflow?.paramTpl} form={form} />
      </div>
    </Form>
    <div className="w-full pr-4">
      <Badge.Ribbon
        text={ t('Discount') } 
        color="#fd12c5"
      >
        <Button 
          type="primary" 
          htmlType="submit" 
          size="large"
          className="w-full h-12" 
          loading={generating}
          onClick={handleGenerate?.bind(null, false)}
        >
          { generating ? t('generating') : t('generate') }
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <span style={{ fontSize: 8 }}> {t('workflow.app.cost')} { (workflow?.costByRecord || workflow?.costByCount)?.replace?.('.00', '') } {t('workflow.app.MCoin')}</span>
            <span style={{ fontSize: 8 }}>({t('workflow.app.left')} { profileData?.mcoinAccount?.balance?.replace?.('.00', '') } {t('workflow.app.MCoin')})</span>
          </div>
        </Button>
      </Badge.Ribbon>
    </div>
  </div>
}

export default ParamContainer;
