import { ComfyNode } from "@/models/sd/comfy";

export const GEN_TYPES =[
  {
    label: '文生图',
    value: 'txt2img'
  },
  {
    label: '图生图',
    value: 'img2img',
    disabled: true
  },
  {
    label: '商品图',
    value: 'product2img',
    disabled: true
  }
];

export type MaletteWorkflowItem = {
  publicId?: string;
  name?: string;
  description?: string;
  cover?: string;
  code?: string;
  isPrivate?: string;

  inviteCodes?: string;
  content?: Record<string, ComfyNode>;
};

export type  WorkflowItem = {
  costByRecord: any;
  costByCount: any;
  publicId?: string;
  name?: string;
  description?: string;
  cover?: string;
  code?: string;
  isPrivate?: string;

  inviteCodes?: string;
  contentTpl?: Record<string, ComfyNode>;
  paramTpl?: Record<string, unknown>;
  outputTpl?: Record<string, unknown>;

};


export interface FieldData {
  name: string | number | (string | number)[];
  value?: unknown;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

export const DEFAULT_COVER = 'https://i.ablula.tech/malette/assets/logo.png';