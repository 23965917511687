

function removeHtmlBodyStyles(htmlString) {
  // 创建一个DOM解析器实例
  const parser = new DOMParser();
  // 解析HTML字符串为DOM文档
  const doc = parser.parseFromString(htmlString, "text/html");

  // 获取所有<style>标签
  const styles = Array.from(doc.getElementsByTagName("style"));

  // 遍历每个<style>标签
  styles.forEach(styleEl => {
    // 获取样式文本内容
    let cssText = styleEl.textContent;
    // 移除 body 和 html 的样式规则
    cssText = cssText.replace(/(@media.*?\{[\s\S]*\}|[^@][\s\S]*)(body|html)\s*\{[\s\S]*?\}/gim, '');
    // 更新<style>标签的内容
    styleEl.textContent = cssText;
  });

  // 序列化修改后的DOM为新的HTML字符串
  return new XMLSerializer().serializeToString(doc);
}

const getHtmlContent = (data) => {
  let htmlContent = removeHtmlBodyStyles(data)
  if (htmlContent) {
    htmlContent = htmlContent.replace(/```html/, '```');
    const start = htmlContent.indexOf('```');
    const end = htmlContent.lastIndexOf('```');
    htmlContent = htmlContent.substring(start + 3, end);
  }
  return htmlContent;
};

// React 组件
const HtmlOutputRender = ({ data, isMobilePortrait }) => {
  return (
    <>
      {/* 使用一个独立的样式环境 */}
      <style>{`
        /* 在这里定义所有的样式 */
        .preview-container {
          /* 定义容器的样式 */
          isolation: isolate; /* 防止样式溢出 */
          /* 更多样式... */
          height: ${isMobilePortrait ? '' : '100%'};
          max-height: ${isMobilePortrait ? '600px' : 'calc(100vh - 225px)'};
          overflow-y: auto; /* 当内容超出时显示滚动条 */
          border: 1px solid #ccc; /* 可选：添加边框以便更容易识别容器边界 */
          padding: 1rem;
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }
      `}</style>
      <div className="preview-container" 
           dangerouslySetInnerHTML={{ __html: getHtmlContent(data) }} />
    </>
  );
}

export const TextOutputRender = ({ data, isMobilePortrait }) => {
  return (
    <div>
      {data}
    </div>
  );
}

export default HtmlOutputRender;
