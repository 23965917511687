import { WorkflowItem } from "@/constants";

export interface IAppWorkflowPreviewProps {   
  previewId?: string;
  type: 'aiAppWorkflow' | 'comfyuiWorkflow';
}

const AppWorkflowPreview = (props: IAppWorkflowPreviewProps) => {

  const { previewId, type } = props;

  if (!previewId) return null;

  return <div className="w-full h-[calc(100vh-300px)]">
    {/* 禁止选中 */}
    <iframe
      title="App Workflow Preview"
      src={`/comfyui/preview?isPreview=true&type=${type}&id=${previewId}`} 
      style={{ width: '100%', height: '100%'}}
    />
  </div>
}

export default AppWorkflowPreview;  